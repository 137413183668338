import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import loader from "../images/loader2.gif";
import styled from "styled-components";
import axios from "axios";
import * as endpoints from "../constants/endpoints";
import authHeader from "../services/auth-header";
import {downloadFile} from "../helpers/downloadFile.js";
import {displayError} from "../helpers/formatErrorCode";

const DropdownGroup = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: center;
  gap: 50px;
`;


const StyledSelect = styled.select`
  width: 200px;
  border-radius: 5px;
  height: 35px;
  background: white;
  color: gray;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 14px;
  border: 1px solid gray;
  outline: none;
    option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    }
  
  &:hover {
    cursor: pointer;
  }
`;

const UploadLogisticXML = (props) =>  {

    const uploadFileInputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [customer, setCustomer] = useState();
    const [customers, setCustomers] = useState([]);


    useEffect(() => {
        const header = authHeader();
        axios.get(`${endpoints.GET_CUSTOMERS}`, {
            headers: header
        })
            .then(response => {
                setCustomers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the customers!', error);
            });
    }, []);  // Empty array means this effect runs once on component mount

    const handleSelectCustomer = (event) => {
        setCustomer(event.target.value);
    }
    const handleFileUploading = (event) => {
        setIsUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        axios.post(`${endpoints.IMPORT_LOGISTIC_XML}?consigneeTraderId=${customer}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader()
            },
        })
            .then(response => {
                setIsUploading(false);
                downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
            })
            .catch(async error => {
                setIsUploading(false);
                displayError(error)
            });
    }
    const handleUploadFileClick = () => {
        uploadFileInputRef.current.click();
    }

    return (
        <Fragment>
            <Row md={4} className="row justify-content-center">
                <Col md={5} className="text-center">
                    <h3 className="text-center mt-5 mb-4">Качване на XML</h3>
                    <DropdownGroup onChange={handleSelectCustomer}>
                        <StyledSelect onChange={handleSelectCustomer}>
                            <option value="">EU Client</option>
                            {customers.map((customer, index) => (
                                <option key={index} value={customer.id}>{customer.traderName}</option>
                            ))}
                        </StyledSelect>
                        {isUploading === false && <Button variant="success" onClick={handleUploadFileClick}>Избери XML</Button>}
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={uploadFileInputRef}
                            accept=".xml"
                            onChange={handleFileUploading}
                        />
                    </DropdownGroup>
                </Col>
            </Row>
            {isUploading === true && (
                <div
                    className="d-flex align-items-center justify-content-center ovarlay-loader"
                    style={{ minHeight: "300px" }}
                >
                    <div className="text-center">
                        <img src={loader} alt="" />
                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default UploadLogisticXML;
import React, {useState} from "react";
import Users from "./Users";
import ArchivedPeriods from "./ArchivedPeriods";
import {Button} from "react-bootstrap";

function SuperAdminHome() {
  const [switcher, setSwitcher] = useState(true);
  return (
    <div className="container pt-4">
      <div className="d-flex mb-4 justify-content-center">
        <Button
          className="me-5"
          onClick={() => setSwitcher(true)}
          variant={switcher ? "primary" : "secondary"}
        >Потребители</Button>
        <Button
          onClick={() => setSwitcher(false)}
          variant={!switcher ? "primary" : "secondary"}
        >Преглед на минали периоди</Button>
      </div>
      <div>
        {switcher ? <Users/> : <ArchivedPeriods/>}
      </div>
    </div>
  );
}

export default SuperAdminHome;

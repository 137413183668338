import React from "react";
import {connect} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';
import jwt_decode from "jwt-decode";

const mapStateToProps = (state) => ({
  userIsLoggedIn: state.auth.isLoggedIn,
  userToken: state.auth?.user?.token,
})

const RoleRoute = ({requiredRole, userIsLoggedIn, userToken}) => {
  if (!userIsLoggedIn) return <Navigate to="/login"/>;

  const decoded = jwt_decode(userToken);
  const roleStr = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
  const role = decoded[roleStr];

  return (role === requiredRole) ? <Outlet/> : <Navigate to="/login"/>;
}

export default connect(mapStateToProps, null)(RoleRoute);
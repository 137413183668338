import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  // need to fix this to this authActionTypes.SET_MESSAGE
  switch (type) {
    case SET_MESSAGE:
      return { message: payload };

    case CLEAR_MESSAGE:
      return { message: "" };

    default:
      return state;
  }
}

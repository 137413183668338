import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux'
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
import jwt_decode from "jwt-decode";
import { getUserProfile } from '../store/users/action'
import styled from "styled-components";
import { TableStyles } from "../components/Style.js"
import axios from "axios";
import authHeader from "../services/auth-header";
import { displayError } from "../helpers/formatErrorCode"
import * as endpoints from "../constants/endpoints";

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userProfile: state.users.user,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: bindActionCreators(getUserProfile, dispatch),
  }
}

const Styles = styled(TableStyles)`
  padding: 1rem;

  table {

    th,
    td {
      padding: 10px;
    }
  }
`

const Profile = ({user, userProfile, getUserProfile}) => {
  var decoded = jwt_decode(user.token);
  const idKey = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'

   useEffect(() => {
    getUserProfile(decoded[idKey])
  }, []);

   useEffect(() => {
    getWarehouse()
  }, []);

  const [state, setState] = useState({
    warehouses: null,
  });

  const getWarehouse = () => {
    const header = authHeader()

    axios.get(`${endpoints.GET_WAREHOUSES}`,{ headers: header, })
     .then((response) => {
       setState((state) => ({ ...state, warehouses: response.data }));
    })
    .catch(error => {
      displayError(error)
    });
  }

  return (
    <Styles>
      <div className="container-fluid pt-4">
        <h4 className="mb-4 mt-5">Personal Information</h4>

        <Table bordered >
          <thead>
            <tr>
              <th>Име</th>
              <th>Презиме</th>
              <th>Фамилия</th>
              <th>ЕГН</th>
              <th>Потребителско име</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userProfile.firstName}</td>
              <td>{userProfile.middleName}</td>
              <td>{userProfile.familyName}</td>
              <td>{userProfile.egn}</td>
              <td>{userProfile.username}</td>
            </tr>
          </tbody>
        </Table>

        <h4 className="mb-4 mt-4">Складова информация</h4>

        <Table bordered >
          <thead>
            <tr>
              <th>WIN</th>
              <th>TaxW_Address</th>
              <th>WCIN</th>
              <th>Bulstat</th>
              <th>TraderName</th>
              <th>TraderAddressTown</th>
            </tr>
          </thead>

          <tbody>
            {state.warehouses !== null && state.warehouses.map(warehouse => (
              <tr key={warehouse.id}>
                <td>{warehouse.taxWhHolderId}</td>
                <td>{warehouse.taxWarehousePostCode} {warehouse.taxWarehouseTown} {warehouse.taxWarehouseStreet}</td>
                <td>{warehouse.licWhHolderId}</td>
                <td>
                  {warehouse.bulstat}
                </td>
                <td>{warehouse.licensedWarehouseHolderName}</td>
                <td>{warehouse.licWhHolderPostCode} {warehouse.cityOfTaxAuthorities} {warehouse.licWhHolderStreet}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    </Styles>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

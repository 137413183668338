import { usersActionTypes } from './action'

const usersInitialState = {
  users: [
  ],
  user: {}
}

export default function reducer(state = usersInitialState, action) {
  switch (action.type) {
    case usersActionTypes.ADD:
	    return Object.assign({}, state, {
        users: [...state.users, action.payload],
      })

    case usersActionTypes.REMOVE:
  	  return Object.assign({}, state, {
        users: state.users.filter((obj) => {return action.payload !==  obj.id})
      })

    case usersActionTypes.UPDATE: {
      const index = state.users.findIndex(user => user.id === action.payload.id)
      const users = state.users
      users[index] = action.payload

      return {
          users: users
      };
    }

    case usersActionTypes.ACTIVATE: {
      const index = state.users.findIndex(user => user.id === action.payload.id)
      const users = state.users
      users[index].isActive = !users[index].isActive

      return {
          users: [...users]
      };
    }

  	case usersActionTypes.GET_ALL: {
      return Object.assign({}, state, {
        users: action.payload,
      })
    }

    case usersActionTypes.GET_PROFILE: {
      return Object.assign({}, state, {
        user: action.payload,
      })
    }

    default:
      return state
    }


}

import { toast } from "react-toastify";
import i18next from "i18next";

export const displayError = (error) => {
	const errorTitle = Object.keys(error.response.data.errors)
    const params = error.response.data.errors[errorTitle]

    if ( params !== null ) {
      //function with args
      let settings = {}
      for (var i = 0; i < params.length; i++) {
        settings[i] = params[i]
      }

      toast.error(i18next.t(errorTitle, settings), { autoClose: 10000 });
    } else {
      toast.error(i18next.t(errorTitle), { autoClose: 10000 });
    }
}

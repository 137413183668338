import React from 'react';
import Header from '../components/Header';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  userIsLoggedIn: state.auth.isLoggedIn,
})

const MainLayout = ({ children, userIsLoggedIn }) => {
    return (
    <React.Fragment>
    	{userIsLoggedIn ? <Header /> : null }

        <div className="main">
        	<main>{children}</main>
        </div>
    </React.Fragment>
    );
};

export default connect(mapStateToProps, null)(MainLayout);

import { periodActionTypes } from './action'

const periodInitialState = {
  period: {
  		startDate: '2022-01-01T22:00:00.000Z',
      endDate: '2022-01-09T22:00:00.000Z',
      isPeriodArchived: false
  	}

}

export default function reducer(state = periodInitialState, action) {
  switch (action.type) {

    case periodActionTypes.SET_PERIOD_DATE:
	  return {
      ...state,
      period: {
        startDate: action.payload.start,
        endDate: action.payload.end
      }
    }

   //  case periodActionTypes.SET_END_DATE:
	  // return {
   //    ...state,
   //    period: {
   //      endDate: action.payload
   //    }
   //  }

	case periodActionTypes.CLOSE_PERIOD:
       return Object.assign({}, state, {
        period: [state.period.isPeriodArchived, action.payload],
      })
    default:
      return state
  }
}

import React from "react";
import * as Icon from "react-bootstrap-icons";
import {Col, Row} from "react-bootstrap";
import {currencyFormat, spacingFormat} from "../helpers/currencyFormatter";

const PeriodStatisticsTables = ({period}) => {
    const highlightCell = (key) => {
        if (period?.csvStatisticsMod !== null) {
            return (
                period?.csvStatisticsOrg[`${key}`] !==
                period?.csvStatisticsMod[`${key}`]
            );
        }
    };

    return period ? <>
        {period.csvStatisticsOrg === null && period.file_sap === null && (
            <div className="text-center mt-5 mb-4">
                <Icon.FileEarmarkCodeFill size={326}/>
            </div>
        )}
        <div className="text-center mb-4 justify-content-between">
            <Row className="justify-content-between">
                {period.csvStatisticsOrg !== null && (
                    <Col md={7}>
                        <table className="table excise-table caption-top">
                            <caption>Сравнителна таблица</caption>
                            <thead className="table-info">
                            <tr>
                                <th>Сравнителна таблица</th>
                                <th>Брой документи (старо)</th>
                                <th>Количество (старо)</th>
                                <th>Брой документи (ново)</th>
                                <th>Количество (ново)</th>
                            </tr>
                            </thead>

                            <tbody className="text-nowrap">
                            <tr>
                                <th>Брой документи</th>
                                <td colSpan={2}>{spacingFormat(period.csvStatisticsOrg.elementsCount)}</td>
                                <td colSpan={2}>{period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.elementsCount) : ''}</td>
                            </tr>
                            <tr>
                                <th>Брой стоки</th>
                                <td colSpan={2}>{spacingFormat(period.csvStatisticsOrg.goodsCount)}</td>
                                <td colSpan={2}>{period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.goodsCount) : ''}</td>
                            </tr>

                            <tr>
                                <th>Начално количество</th>
                                <td>{spacingFormat(period.csvStatisticsOrg.warehouseGoodsInitialAmountCount)}</td>
                                <td>{currencyFormat(period.csvStatisticsOrg.warehouseGoodsInitialAmount)}</td>
                                <td className={highlightCell("warehouseGoodsInitialAmountCount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.warehouseGoodsInitialAmountCount) : ''}</td>
                                <td className={highlightCell("warehouseGoodsInitialAmount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? currencyFormat(period.csvStatisticsMod.warehouseGoodsInitialAmount) : ''}</td>
                            </tr>

                            <tr>
                                <th>Общо въведено количество</th>
                                <td>{spacingFormat(period.csvStatisticsOrg.initialAmountCount)}</td>
                                <td>{currencyFormat(period.csvStatisticsOrg.initialAmountQuantity)}</td>
                                <td className={highlightCell("initialAmountCount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.initialAmountCount) : ''}</td>
                                <td className={highlightCell("initialAmountQuantity") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? currencyFormat(period.csvStatisticsMod.initialAmountQuantity) : ''}</td>
                            </tr>

                            <tr>
                                <th>Унищожени стоки</th>
                                <td>{spacingFormat(period.csvStatisticsOrg.destroyedGoodsCount)}</td>
                                <td>{currencyFormat(period.csvStatisticsOrg.destroyedGoods)}</td>
                                <td className={highlightCell("destroyedGoodsCount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.destroyedGoodsCount) : ''}</td>
                                <td className={highlightCell("destroyedGoods") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? currencyFormat(period.csvStatisticsMod.destroyedGoods) : ''}< /td>
                            </tr>

                            <tr>
                                <th>Вложени&nbsp;в&nbsp;производство&nbsp;(предназначение&nbsp;90)</th>
                                <td>{spacingFormat(period.csvStatisticsOrg.investedRemovedGoodsCount)}</td>
                                <td>{currencyFormat(period.csvStatisticsOrg.investedRemovedGoodsAmount)}</td>
                                <td className={highlightCell("investedRemovedGoodsCount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.investedRemovedGoodsCount) : ''}</td>
                                <td className={highlightCell("investedRemovedGoodsAmount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? currencyFormat(period.csvStatisticsMod.investedRemovedGoodsAmount) : ''}</td>
                            </tr>

                            <tr>
                                <th>Количество подлежащо на деклариране</th>
                                <td>{spacingFormat(period.csvStatisticsOrg.exciseRemovedGoodsCount)}</td>
                                <td>{currencyFormat(period.csvStatisticsOrg.exciseRemovedGoodsAmount)}</td>
                                <td className={highlightCell("exciseRemovedGoodsCount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.exciseRemovedGoodsCount) : ''}</td>
                                <td className={highlightCell("exciseRemovedGoodsAmount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? currencyFormat(period.csvStatisticsMod.exciseRemovedGoodsAmount) : ''}</td>
                            </tr>

                            <tr>
                                <th>Общо изведено количество</th>
                                <td>{spacingFormat(period.csvStatisticsOrg.removedGoodsCount)}</td>
                                <td>{currencyFormat(period.csvStatisticsOrg.removedGoodsAmount)}
                                </td>
                                <td className={highlightCell("removedGoodsCount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.removedGoodsCount) : ''}
                                </td>
                                <td className={highlightCell("removedGoodsAmount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? currencyFormat(period.csvStatisticsMod.removedGoodsAmount) : ''}
                                </td>
                            </tr>

                            <tr>
                                <th>Крайно количество</th>
                                <td>{spacingFormat(period.csvStatisticsOrg.finalAmountCount)}</td>
                                <td>{currencyFormat(period.csvStatisticsOrg.finalAmount)}</td>
                                <td className={highlightCell("finalAmountCount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? spacingFormat(period.csvStatisticsMod.finalAmountCount) : ''}</td>
                                <td className={highlightCell("finalAmount") ? "highlighted-cell" : ""}>
                                    {period.csvStatisticsMod !== null ? currencyFormat(period.csvStatisticsMod.finalAmount) : ''}</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                )}

                {period.stockLogStatistic !== null && (
                    <Col md={5}>
                        <div>
                            <table className="table excise-table caption-top">
                                <caption>Дневник складова наличност</caption>
                                <thead className="table-info">
                                <tr>
                                    <th>Дневник на складова наличност<br/>XML файл</th>
                                    <th>Брой документи</th>
                                    <th>Количество</th>
                                </tr>
                                </thead>

                                <tbody className="text-nowrap">
                                <tr>
                                    <th>Документи</th>
                                    <td>{spacingFormat(period.stockLogStatistic.elementsCount)}</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <th>Стоки</th>
                                    <td>{spacingFormat(period.stockLogStatistic.goodsCount)}</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <th className="text-left">Начално количество</th>
                                    <td>{spacingFormat(period.stockLogStatistic.warehouseGoodsInitialAmountCount)}</td>
                                    <td>{currencyFormat(period.stockLogStatistic.warehouseGoodsInitialAmount)}</td>
                                </tr>
                                <tr>
                                    <th>Общо въведено количество</th>
                                    <td>{spacingFormat(period.stockLogStatistic.initialAmountCount)}</td>
                                    <td>{currencyFormat(period.stockLogStatistic.initialAmountQuantity)}</td>
                                </tr>
                                <tr>
                                    <th>Унищожени стоки (предназначение 58)</th>
                                    <td>{spacingFormat(period.stockLogStatistic.destroyedGoodsCount)}</td>
                                    <td>{currencyFormat(period.stockLogStatistic.destroyedGoods)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Вложени в производство (предназначение 90)</th>
                                    <td>{spacingFormat(period.stockLogStatistic.investedRemovedGoodsCount)}</td>
                                    <td>{currencyFormat(period.stockLogStatistic.investedRemovedGoodsAmount)}</td>
                                </tr>
                                <tr>
                                    <th>Количество, подлежащо на деклариране в АД</th>
                                    <td>{spacingFormat(period.stockLogStatistic.exciseRemovedGoodsCount)}</td>
                                    <td>{currencyFormat(period.stockLogStatistic.exciseRemovedGoodsAmount)}</td>
                                </tr>
                                <tr>
                                    <th>Общо изведено количество</th>
                                    <td>{spacingFormat(period.stockLogStatistic.removedGoodsCount)}</td>
                                    <td>{currencyFormat(period.stockLogStatistic.removedGoodsAmount)}</td>
                                </tr>
                                <tr>
                                    <th>Крайно количество</th>
                                    <td>{spacingFormat(period.stockLogStatistic.finalAmountCount)}</td>
                                    <td>{currencyFormat(period.stockLogStatistic.finalAmount)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                )}
            </Row>
        </div>

        {period.exciseDeclarationGroupStatistics !== null && (
            <Col md={7}>
                <div>
                    <table className="table excise-table caption-top">
                        <caption>Обобщена информация за акцизна декларация</caption>
                        <thead className="table-info">
                        <tr>
                            <th>Предназначение</th>
                            <th>Начин на плащане</th>
                            <th>Количество</th>
                            <th>Данъчна основа</th>
                            <th>Размер на акциза</th>
                            <th>Внесен Акциз</th>
                        </tr>
                        </thead>
                        <tbody className="text-nowrap">
                        {period.exciseDeclarationGroupStatistics.groups
                            .sort((a, b) => a.purpose > b.purpose ? 1 : -1)
                            .map(
                            (row, index) => (
                                <tr key={`row-${index}`}>
                                    <th>{row.purpose}</th>
                                    <td>{row.paymentType}</td>
                                    <td>{currencyFormat(row.docQuantity)}</td>
                                    <td>{currencyFormat(row.taxBase)}</td>
                                    <td>{currencyFormat(row.exciseAmount)}</td>
                                    <td>{currencyFormat(row.paidExcise)}</td>
                                </tr>
                            )
                        )}
                        </tbody>

                        <tfoot className="text-nowrap">
                        <tr>
                            <th colSpan={2} className="text-right">Общо</th>
                            <td>{currencyFormat(period.exciseDeclarationGroupStatistics.docQuantity)}</td>
                            <td>{currencyFormat(period.exciseDeclarationGroupStatistics.taxBase)}</td>
                            <td>{currencyFormat(period.exciseDeclarationGroupStatistics.exciseAmount)}</td>
                            <td>{currencyFormat(period.exciseDeclarationGroupStatistics.paidExcise)}</td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </Col>
        )}</> : <></>
}

export default PeriodStatisticsTables;

import styled from "styled-components";

export const TableStyles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid #d1d1d1;
    border-color: #d1d1d1;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      vertical-align: middle;
      background: #fff;
      border-color: #d1d1d1;

      :last-child {
        border-right: 0;
      }

      .form-select,
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
        padding: 10px;
        height: 58px;
        line-height: 58px;
        border-radius: 0;
      }
      .form-select {
        padding: 0 30px 0 10px;
        min-width: 150px;
      }
      .actions {
        padding: 10px;
        background: #fff;
      }
    }

    th {
      padding: 10px;
      border-bottom: 1px solid #d1d1d1;
    }

    thead tr {
      border-color: #d1d1d1;
    }

    tr.disabled {
      .actions,
      .form-select,
      input {
        background: #dcdcdc;
      }
    }
    & > :not(:first-child) {
      border-color: #d1d1d1;
    }
  }
`;

import React, {useState, useRef, useEffect} from "react";
import {ArrowLeft, ChevronLeft} from 'react-bootstrap-icons';
import {useNavigate, useParams} from "react-router-dom";
import {bindActionCreators} from 'redux'
import {connect} from "react-redux";
import {Col, Row, Button, Form} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import * as endpoints from "../constants/endpoints";
import loader from "../images/loader2.gif";
import {toast} from "react-toastify";
import styled from "styled-components";
import {TableStyles} from "../components/Style.js";
import {downloadFile} from "../helpers/downloadFile.js";
import axios from "axios";
import authHeader from "../services/auth-header";
import {setPeriodDate} from '../store/period/action'
import {convertDate} from "../helpers/dateFormatter";
import {displayError} from "../helpers/formatErrorCode"
import {currencyFormat, spacingFormat} from "../helpers/currencyFormatter";
import PeriodStatisticsTables from "../components/PeriodStatisticsTables";
import {browserDownloadFile} from "../helpers/browserDownloadFile";

const mapStateToProps = (state) => ({
    period: state.period.period,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setPeriodDate: bindActionCreators(setPeriodDate, dispatch),
    }
}

const Index = ({user, period, setPeriodDate}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [periodData, setPeriod] = useState(null);

    const [state, setState] = useState({
        file_sap: null,
        file_csv: null,
        isLoading: false,
        showOverlayLoader: false,
        isSAPLoaded: false,
        isCSVGenerated: false,
        isCSVUploaded: false,

        csvStatisticsOrg: null,
        csvStatisticsMod: null,
        exciseDeclarationGroupStatistics: null,
        stockLogStatistic: null,
        warehouse: null
    });

    const fileSAPRef = useRef();
    const fileCSVRef = useRef();

    useEffect(() => {
        if (id !== undefined) {
            getStatistics(id);
            getPeriod(id);
            getWarehouse(id)
        }
    }, []);

    const getStatistics = (id) => {
        setState((state) => ({...state, showOverlayLoader: true}));
        const header = authHeader();

        axios
            .get(`${endpoints.GET_PERIOD_STATISTICS}/${id}`, {headers: header})
            .then((response) => {
                if (response.data.excisePeriodOriginalCsvStatisticsResponse !== null) {
                    setState((state) => ({
                        ...state,
                        csvStatisticsOrg: response.data.excisePeriodOriginalCsvStatisticsResponse,
                        isSAPLoaded: true,
                    }));
                }

                if (response.data.excisePeriodModifiedCsvStatisticsResponse !== null) {
                    setState((state) => ({
                        ...state,
                        csvStatisticsMod: response.data.excisePeriodModifiedCsvStatisticsResponse,
                    }));
                }

                if (response.data.exciseDeclarationGroupStatistics !== null) {
                    setState((state) => ({
                        ...state,
                        exciseDeclarationGroupStatistics: response.data.exciseDeclarationGroupStatistics,
                    }));
                }

                if (response.data.excisePeriodStockLogStatisticResponse !== null) {
                    setState((state) => ({
                        ...state,
                        stockLogStatistic: response.data.excisePeriodStockLogStatisticResponse,
                    }));
                }

                setState((state) => ({...state, showOverlayLoader: false}));
            })
            .catch(error => {
                displayError(error)
                setState((state) => ({...state, showOverlayLoader: false}));
            });
    };

    const getPeriod = (id) => {
        const header = authHeader();

        axios
            .get(`${endpoints.GET_PERIOD_BY_ID}?id=${id}`, {headers: header})
            .then((response) => {
                setPeriod(response.data);

                setPeriodDate({
                    "start": response.data.startDate,
                    "end": response.data.endDate,
                })
            })
            .catch(error => {
                displayError(error)
                setState((state) => ({...state, showOverlayLoader: false}));
            });
    };

    useEffect(() => {
        if (state.file_sap !== null) {
            uploadSAPFile();
            getPeriod(id);
        }
    }, [state.file_sap]);

    useEffect(() => {
        if (state.file_sap !== null) {
            getStatistics(id);
            getPeriod(id);
        }
    }, [state.isSAPLoaded]);


    useEffect(() => {
        if (state.file_csv !== null) {

            uploadCSVFile();
        }
    }, [state.file_csv]);

    useEffect(() => {
        getStatistics(id)
    }, [state.isCSVUploaded]);

    useEffect(() => {
        getStatistics(id)
    }, [state.isSAPLoaded]);

    const onFileChange = (event, fileName) => {
        const file = event.target.files[0];

        if (fileName === "file_sap") {
            setState((state) => ({...state, file_sap: file}));
        } else {
            setState((state) => ({...state, file_csv: file}));
        }
    };

    const uploadSAPFile = () => {
        setState((state) => ({...state, showOverlayLoader: true}));
        const file = state.file_sap;
        const data = new FormData();
        data.append("sapXmlDocument", file);

        const header = authHeader();
        header["Content-Type"] = "multipart/form-data";

        axios
            .post(`${endpoints.IMPORT_SAP}?excisePeriodId=${id}`, data, {
                headers: header,
            })
            .then((response) => {
                toast.success("SAP файлът е качен");
                if (response.data.errors && response.data.errors.length > 0) {
                    for (const error of response.data.errors) {
                        toast.error(error, {autoClose: false});
                    }
                }
                setState((state) => ({
                    ...state,
                    showOverlayLoader: false,
                    isSAPLoaded: true,
                }));
            })
            .catch(error => {
                displayError(error)
                setState((state) => ({...state, showOverlayLoader: false}));
            });
    };

    const generateCSVFile = () => {
        setState((state) => ({...state, showOverlayLoader: true}));

        browserDownloadFile(`${endpoints.GENERATE_CSV_FILE}/${id}`);

        setTimeout(() => setState((state) => ({...state, showOverlayLoader: false, isCSVGenerated: true})), 9000);
    }

    const uploadCSVFile = () => {
        setState((state) => ({...state, showOverlayLoader: true, isCSVUploaded: false}));

        const file = state.file_csv;
        const data = new FormData();
        data.append("csvDocument", file);

        const header = authHeader();
        header["Content-Type"] = "multipart/form-data";

        axios
            .post(`${endpoints.IMPORT_CSV}?excisePeriodId=${id}`, data, {
                headers: header,
            })
            .then((response) => {
                toast.success("CSV файлът се качи");

                if (response.data.errors && response.data.errors.length > 0) {
                    for (const error of response.data.errors) {
                        toast.error(error, {autoClose: false});
                    }
                }

                setState((state) => ({
                    ...state,
                    showOverlayLoader: false,
                    isCSVUploaded: true,
                }));
            })
            .catch(error => {
                displayError(error)
                setState((state) => ({...state, showOverlayLoader: false}));
            });
    };

    const generateWarehouseExport = () => {
        setState((state) => ({...state, showOverlayLoader: true}));
        const header = authHeader();

        axios
            .post(
                `${endpoints.GENERATE_WAREHOUSE_LOG}/${id}`,
                {},
                {headers: header}
            )
            .then((response) => {
                toast.success("Файлът за складова наличност се генерира");

                setState((state) => ({...state, showOverlayLoader: false}));

                downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
            })
            .catch(error => {
                displayError(error)
                setState((state) => ({...state, showOverlayLoader: false}));
            });
    };

    const generateXML = () => {
        setState((state) => ({...state, showOverlayLoader: true}));
        const header = authHeader();

        axios
            .post(
                `${endpoints.GENERATE_XML_DECLARATION}/?excisePeriodId=${id}`,
                {},
                {headers: header}
            )
            .then((response) => {
                toast.success("Акцизна декларация се генерира");

                setState((state) => ({...state, showOverlayLoader: false}));

                downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
            })
            .catch(error => {
                displayError(error)
                setState((state) => ({...state, showOverlayLoader: false}));
            });
    };

    const getWarehouse = (id) => {
        const header = authHeader()

        axios.get(`${endpoints.GET_WAREHOUSE}/${id}`, {headers: header,})
            .then((response) => {
                setState((state) => ({...state, warehouse: response.data}));
            })
            .catch(error => {
                displayError(error)
            });
    }

    const togglePeriodActivation = (event) => {
        setState((state) => ({...state, showOverlayLoader: true}));
        const header = authHeader();

        if (periodData.isArchived) {
            axios
                .post(`${endpoints.ACTIVATE_PERIOD}/${id}`, {}, {headers: header})
                .then((response) => {
                    toast.success("Периодът се активира!");

                    getPeriod(id);
                    setState((state) => ({...state, showOverlayLoader: false}));
                })
                .catch(error => {
                    displayError(error)
                    setState((state) => ({...state, showOverlayLoader: false}));
                });
        } else {
            axios
                .post(`${endpoints.ARCHIVE_PERIOD}/${id}`, {}, {headers: header})
                .then((response) => {
                    toast.success("Периодът се архивира!");

                    setPeriodDate({
                        "start": period.startDate,
                        "end": period.endDate,
                    })

                    setState((state) => ({...state, showOverlayLoader: false}));

                    navigate("/period-archiving-success");
                })
                .catch(error => {
                    displayError(error)
                    setState((state) => ({...state, showOverlayLoader: false}));
                });
        }
    };

    return (
        <div className="container-fluid pt-4">
            <div className="d-flex align-items-center justify-content-end mb-4 excise-info-panel">
                <h6 className="mb-0">
                    Начална дата: {convertDate(new Date(period.startDate)).toISOString().split('T')[0]}
                </h6>

                <h6 className="mb-0 mx-4">
                    Крайна дата: {convertDate(new Date(period.endDate)).toISOString().split('T')[0]}
                </h6>

                {periodData !== null && periodData.isArchived === true ? (
                    <Button variant="outline-danger" onClick={(event) => togglePeriodActivation(event)}>Активирай
                        период</Button>
                ) : (
                    <Button variant="outline-danger" onClick={(event) => togglePeriodActivation(event)}>Архивирай
                        период</Button>
                )}
            </div>
            {periodData !== null && !periodData.isArchived === true && (
                <div className="d-flex col-12 mb-2 justify-content-center align-content-center">
                    <div className="d-flex col-3 mb-4 justify-content-start">
                        <ArrowLeft width="32" height="32" onClick={() => navigate(-1)} className="me-3"/>
                        <h4 className="text-center">{periodData.warehouse}</h4>
                    </div>
                    <div className="d-flex col-3 mb-4 justify-content-center">
                    </div>
                    <div className="d-flex col-6 mb-4 justify-content-center">
                        <Button variant="success" className="mb-4" onClick={() => fileSAPRef.current.click()}>Зареди SAP
                            файл</Button>

                        <input ref={fileSAPRef} onChange={(e) => onFileChange(e, "file_sap")} multiple={false}
                               type="file" hidden/>

                        <Button disabled={!state.isSAPLoaded} variant="primary" className="mx-2 mb-4"
                                onClick={generateCSVFile}>Генерирай справка в CSV</Button>

                        <Button disabled={!state.isCSVGenerated} variant="primary" className="mb-4"
                                onClick={() => fileCSVRef.current.click()}>Зареди справка в CSV</Button>

                        <input ref={fileCSVRef} onChange={(e) => onFileChange(e, "file_csv")} multiple={false}
                               type="file" hidden/>

                        <Button disabled={!state.isSAPLoaded} variant="outline-dark" className="mx-2 mb-4"
                                onClick={generateWarehouseExport}>
                            Генерирай складова наличност</Button>

                        <Button disabled={!state.isSAPLoaded} variant="dark mb-4" onClick={generateXML}>Генерирай
                            акцизна декларация</Button>
                    </div>
                </div>
            )}
            {state && <PeriodStatisticsTables period={state}/>}
            {state.showOverlayLoader === true && (
                <div
                    className="d-flex align-items-center justify-content-center ovarlay-loader"
                    style={{minHeight: "300px"}}
                >
                    <div className="text-center">
                        <img src={loader} alt=""/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
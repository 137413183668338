import React from "react";
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const mapStateToProps = (state) => ({
  userIsLoggedIn: state.auth.isLoggedIn,
})

const ProtectedRoute = ({userIsLoggedIn}) => {
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return userIsLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

export default connect(mapStateToProps, null)(ProtectedRoute);

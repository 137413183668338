import axios from "axios";
import * as endpoints from "../constants/endpoints";
import jwt_decode from "jwt-decode";
import {displayError} from "../helpers/formatErrorCode";

const register = (username, email, password) => {
  return axios.post(endpoints.REGISTER, {
    username,
    email,
    password,
  })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      displayError(error);
      return Promise.reject(error);
    });
};

const login = (username, password) => {
  return axios.post(endpoints.LOGIN,
    {
      Username: username,
      Password: password,
    })
    .then((response) => {
      const decoded = jwt_decode(response.data.token);
      const roleStr = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      const nameStr = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/name'

      const role = decoded[roleStr];
      const name = decoded[nameStr]

      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return Promise.resolve(response.data);
    })
    .catch(error => {
      displayError(error);
      return Promise.reject(error);
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
};

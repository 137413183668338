import React, {Fragment, useRef, useState} from 'react';
import axios from 'axios';
import * as endpoints from "../constants/endpoints";
import authHeader from "../services/auth-header";
import {Button, Col, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import loader from "../images/loader2.gif";
import {displayError} from "../helpers/formatErrorCode";

const UploadCustomersMapping = () => {
    const numberOfPackagesFileInputRef = useRef(null);
    const alcoholPercentageFileInputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false)

    const handleFileUploadNumberOfPackages = async (event) => {
        
        setIsUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('csvDocument', file);

        const header = authHeader();
        header["Content-Type"] = "multipart/form-data";


        axios.post(`${endpoints.UPLOAD_LOGISTIC_MAPPING}`, formData, {
            headers: header
        }).then((response) => {
            setIsUploading(false);

            toast.success("Успешно качен мапинг за Customers");

        }).catch(error => {
            setIsUploading(false);
            displayError(error)
        });

    };
        const handleFileUploadAlcoholPercentage = async (event) => {

        setIsUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('csvDocument', file);

        const header = authHeader();
        header["Content-Type"] = "multipart/form-data";


        axios.post(`${endpoints.IMPORT_ALCOHOL_PERCENTAGE}`, formData, {
            headers: header
        }).then((response) => {
            setIsUploading(false);

            toast.success("Успешно качен мапинг за алкохолни проценти");

        }).catch(error => {
            setIsUploading(false);
            displayError(error)
        });

    };

    const handleNumberOfPackagesMappingClick = () => {
        numberOfPackagesFileInputRef.current.click();
    }
    const handleAlcoholPercentageMappingClick = () => {
        alcoholPercentageFileInputRef.current.click();
    }

    return (
        <Fragment>
        <Row md={4} className="row justify-content-center">
            <Col md={4} className="text-center">
                <h3 className="text-center mt-5 mb-4">Качване на мапинги</h3>
                {isUploading === false && <Button variant="success" className="mb-4" onClick={handleNumberOfPackagesMappingClick}>Качи CSV мапинг за Customers</Button>}
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={numberOfPackagesFileInputRef}
                    accept=".csv"
                    onChange={handleFileUploadNumberOfPackages}
            />
                {isUploading === false && <Button variant="success" className="mb-4" onClick={handleAlcoholPercentageMappingClick}>Качи CSV мапинг за алкохолни проценти</Button>}
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={alcoholPercentageFileInputRef}
                    accept=".csv"
                    onChange={handleFileUploadAlcoholPercentage}
            />
            </Col>
        </Row>
            {isUploading === true && (
                <div
                    className="d-flex align-items-center justify-content-center ovarlay-loader"
                    style={{ minHeight: "300px" }}
                >
                    <div className="text-center">
                        <img src={loader} alt="" />
                    </div>
                </div>
            )}
        </Fragment>

    );
};

export default UploadCustomersMapping;

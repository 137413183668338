import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import loader from "../images/loader2.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as endpoints from "../constants/endpoints";
import authHeader from "../services/auth-header";
import { displayError } from "../helpers/formatErrorCode";
import dayjs from "dayjs";
import i18next from "i18next";
import { convertDate } from "../helpers/dateFormatter";
import { toast } from "react-toastify";

const UploadInternalMarketXML = (props) => {
  const uploadFileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorsTableData, setErrorsTableData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getErrors = () => {
    const startDateFormated = startDate
      ? convertDate(startDate).toISOString()
      : null;
    const endDateFormated = endDate ? convertDate(endDate).toISOString() : null;

    const header = authHeader();
    axios
      .get(`${endpoints.GET_INTERNAL_MARKET_ERRORS}`, {
        params: { startDate: startDateFormated, endDate: endDateFormated },
        headers: header,
      })
      .then((response) => {
        setErrorsTableData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the errors!", error);
      });
  };
  useEffect(() => {
    getErrors();
  }, []);

  const handleFileUploading = (event) => {
    setIsUploading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("xmlDocument", file);

    axios
      .post(`${endpoints.IMPORT_INTERNAL_MARKET_XML}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      })
      .then((response) => {
        if (!!response?.data?.errorDetails?.length) {
          setErrorsTableData([response.data]);
          toast.error(
            i18next.t("DOCUMENT_UPLOADED_WITH_ERRORS", {
              documentNumber: response.data.documentNumber,
            }),
            { autoClose: false },
          );
        } else {
          setErrorsTableData([]);
          toast.success(
            i18next.t("DOCUMENT_UPLOADED_WITHOUT_ERRORS", {
              documentNumber: response.data.documentNumber,
            }),
            { autoClose: false },
          );
        }
        setIsUploading(false);
      })
      .catch(async (error) => {
        setIsUploading(false);
        getErrors();
        displayError(error);
      });
  };
  const handleUploadFileClick = () => {
    uploadFileInputRef.current.click();
  };
  const translateError = (errors) => {
    return errors?.map((error, index) => {
      const i18nParams = error.args.reduce((acc, arg, i) => {
        acc[i] = arg;
        return acc;
      }, {});

      return <p key={index}>{i18next.t(error.code, i18nParams)}</p>;
    });
  };

  return (
    <Fragment>
      <Row md={4} className="row justify-content-center">
        <Col md={5} className="text-center">
          <h3 className="text-center mt-5 mb-4">
            Качване на XML за Вътрешен Пазар
          </h3>
          {isUploading === false && (
            <Button
              variant="success"
              className="mb-4"
              onClick={handleUploadFileClick}
            >
              Избери XML
            </Button>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            ref={uploadFileInputRef}
            accept=".xml"
            onChange={handleFileUploading}
          />
        </Col>
      </Row>
      <div className="row d-flex mb-4 justify-content-center">
        <Col md={3}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Изберете начална дата"
            dateFormat="dd/MM/yyyy"
          />
        </Col>

        <Col md={3}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="Изберете крайна дата"
            dateFormat="dd/MM/yyyy"
          />
        </Col>

        <Col md={2}>
          <Button
            onClick={() => getErrors()}
            variant="primary"
            className="w-100"
          >
            Задай
          </Button>
        </Col>
      </div>
      {isUploading === true && (
        <div
          className="d-flex align-items-center justify-content-center ovarlay-loader"
          style={{ minHeight: "300px" }}
        >
          <div className="text-center">
            <img src={loader} alt="" />
          </div>
        </div>
      )}
      <div className="ps-3 pe-3">
        <Table bordered hover>
          <thead>
            <tr>
              <th>Номер на документ</th>
              <th>Грешки</th>
              <th>Дата</th>
            </tr>
          </thead>
          <tbody>
            {!!errorsTableData?.length &&
              errorsTableData?.map((error, index) => {
                return (
                  <tr key={index}>
                    <td>{error.documentNumber}</td>
                    <td>{translateError(error.errorDetails)}</td>
                    <td>{dayjs(error.dateCreated).format("DD/MM/YYYY")}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default UploadInternalMarketXML;

import React, {Fragment, useRef, useState} from 'react';
import axios from 'axios';
import * as endpoints from "../constants/endpoints";
import authHeader from "../services/auth-header";
import {Button, Col, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import loader from "../images/loader2.gif";
import {displayError} from "../helpers/formatErrorCode";
import {UPLOAD_NUMBER_OF_PACKAGES_MAPPING, UPLOAD_REPACK_MAPPING} from "../constants/endpoints";

const UploadMapping = () => {
    const numberOfPackagesFileInputRef = useRef(null);
    const repackFileInputRef = useRef(null);
        const [isUploading, setIsUploading] = useState(false)

    const handleFileUploadNumberOfPackages = async (event) => {
        
        setIsUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('csvDocument', file);

        const header = authHeader();
        header["Content-Type"] = "multipart/form-data";


        axios.post(`${endpoints.UPLOAD_NUMBER_OF_PACKAGES_MAPPING}`, formData, {
            headers: header
        }).then((response) => {
            setIsUploading(false);

            toast.success("Успешно качен мапинг за Number of Packages");

        }).catch(error => {
            setIsUploading(false);
            displayError(error)
        });

    };
    const handleFileUploadRepackProducts = async (event) => {

        setIsUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('csvDocument', file);

        const header = authHeader();
        header["Content-Type"] = "multipart/form-data";


        axios.post(`${endpoints.UPLOAD_REPACK_MAPPING}`, formData, {
            headers: header
        }).then((response) => {
            setIsUploading(false);

            toast.success("Успешно качен мапинг за продукти за препакетиране");

        }).catch(error => {
            setIsUploading(false);
            displayError(error)
        });

    };

    const handleNumberOfPackagesMappingClick = () => {
        numberOfPackagesFileInputRef.current.click();
    }
    const handleRepackMappingClick = () => {
        repackFileInputRef.current.click();
    }

    return (
        <Fragment>
        <Row md={4} className="row justify-content-center">
            <Col md={4} className="text-center">
                <h3 className="text-center mt-5 mb-4">Качване на мапинги</h3>
                {isUploading === false && <Button variant="success" className="mb-4" onClick={handleNumberOfPackagesMappingClick}>Качи CSV мапинг за Number of Packages</Button>}
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={numberOfPackagesFileInputRef}
                    accept=".csv"
                    onChange={handleFileUploadNumberOfPackages}
            />
            </Col>
        </Row>
        <Row md={4} className="row justify-content-center">
                <Col md={4} className="text-center">
                    {isUploading === false && <Button variant="success" className="mb-4" onClick={handleRepackMappingClick}>Качи CSV мапинг за Продукти за препакетиране</Button>}
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={repackFileInputRef}
                        accept=".csv"
                        onChange={handleFileUploadRepackProducts}
                    />
                </Col>
            </Row>
            {isUploading === true && (
                <div
                    className="d-flex align-items-center justify-content-center ovarlay-loader"
                    style={{ minHeight: "300px" }}
                >
                    <div className="text-center">
                        <img src={loader} alt="" />
                    </div>
                </div>
            )}
        </Fragment>

    );
};

export default UploadMapping;

import React from "react";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <div className="home">
      <div className="container py-5">
        <div className="row align-items-center my-5">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul className="nav list-group flex-column">
                <li className=" list-group-item list-group-item-action  nav-item" style={{fontSize: '20px', borderRadius: "10px"}}>
                  <NavLink className="nav-link" to="/new-period-define" style={{color: "black", textAlign:"center"}}>
                      Отваряне на акцизен период за деклариране
                  </NavLink>
                </li>
                <li style={{height:'5px'}}></li>
                <li className="list-group-item list-group-item-action  nav-item" style={{fontSize: '20px', borderRadius: "10px"}}>
                  <NavLink className="nav-link" to="/temporary-period" style={{color: "black", textAlign:"center"}}>
                      Отваряне на временен акцизен период
                  </NavLink>
                </li>
                <li style={{height:'5px'}}></li>
                <li className="list-group-item list-group-item-action  nav-item" style={{fontSize: '20px', borderRadius: "10px"}}>
                  <NavLink className="nav-link" to="/archived-periods" style={{color: "black", textAlign:"center"}}>
                      Преглед на минали периоди
                  </NavLink>
                </li>
                <li style={{height:'5px'}}></li>
                <li className="list-group-item list-group-item-action  nav-item" style={{fontSize: '20px', borderRadius: "10px"}}>
                  <NavLink className="nav-link" to="/profile" style={{color: "black", textAlign:"center"}}>
                      Преглед на статични данни
                  </NavLink>
                </li>
                <li style={{height:'5px'}}></li>
                <li className="list-group-item list-group-item-action  nav-item" style={{fontSize: '20px', borderRadius: "10px"}}>
                    <NavLink className="nav-link" to="/upload-mapping" style={{color: "black", textAlign:"center"}}>
                        Качване на мапинг
                    </NavLink>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

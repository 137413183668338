import React, {useState, useEffect} from "react";
import {Navigate} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {connect} from 'react-redux';
import {Col, Button, Form, Modal} from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Icon from 'react-bootstrap-icons';
import {bindActionCreators} from 'redux'

import * as endpoints from "../constants/endpoints";
import axios from "axios";
import authHeader from "../services/auth-header";
import {toast} from "react-toastify";

import styled from "styled-components";
import {TableStyles} from "../components/Style.js"
import {downloadFile} from "../helpers/downloadFile.js"
import {setPeriodDate} from '../store/period/action'
import {convertDate} from "../helpers/dateFormatter";
import {displayError} from "../helpers/formatErrorCode"
import {translateWarehouseEnumValue} from "../helpers/translateHardcodedData.js"
import loader from "../images/loader2.gif";
import {DELETE_PERIOD, GENERATE_XML_DECLARATION} from "../constants/endpoints";
import jwt_decode from "jwt-decode";
import {browserDownloadFile} from "../helpers/browserDownloadFile";

// TODO
const mapStateToProps = (state) => ({
  //user: state.auth.user,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setPeriodDate: bindActionCreators(setPeriodDate, dispatch),
  }
}

const Styles = styled(TableStyles)`
  padding: 1rem;

  table {

    th,
    td {
      padding: 10px;
    }
  }
`

const ArchivedPeriodsFilter = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [periodsOptions, setPeriodsOptions] = useState(null);
  const [periods, setPeriods] = useState(null);
  const [selectedPeriodId, setPeriodId] = useState('');
  const [show, setShowModal] = useState(false);
  const navigate = useNavigate();

  const [state, setState] = useState({
    showOverlayLoader: false,
    isTableVisible: false,
    isSuperAdmin: false
  });

  useEffect(() => {
    getPeriodsOptions()
    getUserRole()
  }, [])

  const getUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    var decoded = jwt_decode(user.token);
    const roleStr = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    const role = decoded[roleStr];

    if (role === "SuperAdmin") {
      setState((state) => ({...state, isSuperAdmin: true}));
    } else {
      setState((state) => ({...state, isSuperAdmin: false}));
    }
  }
  const getPeriodsOptions = () => {
    const header = authHeader()

    axios.get(endpoints.GET_PERIODS_ALL,
      {headers: header,})
      .then((response) => {
        setPeriodsOptions(response.data)
      })
      .catch(error => {
        displayError(error)
      });
  }

  const getPeriods = (id) => {
    const header = authHeader()

    axios.get(`${endpoints.GET_PERIOD_BY_ID}?id=${id}`,
      {headers: header,})
      .then((response) => {
        setPeriods([response.data])
      })
      .catch(error => {
        displayError(error)
      });
  }

  const handlePredefinedPeriodSelected = (event) => {
    if (event.target.value.length) {
      setState((state) => ({...state, isTableVisible: true}));
      getPeriods(event.target.value)
      setPeriodId(event.target.value)
      setStartDate(null);
      setEndDate(null);
    } else {
      setPeriods(null)
      setPeriodId('')
      setState((state) => ({...state, isTableVisible: false}));
    }
  }

  const sendPeriod = () => {
    const header = authHeader()

    if (startDate && endDate) {
      const startDateFormated = convertDate(startDate).toISOString().split('T')[0];
      const endDateFormated = convertDate(endDate).toISOString().split('T')[0]

      axios.get(`${endpoints.GET_PERIODS_BY_DATE}?startDate=${startDateFormated}&endDate=${endDateFormated}`,
        {headers: header,})
        .then((response) => {
          if (response.data.length > 0) {
            setState((state) => ({...state, isTableVisible: true}));
            setPeriods(response.data)
          } else {
            setState((state) => ({...state, isTableVisible: false}));
            toast.error("Няма намерени периоди за тези дати");
          }
        })
        .catch(error => {
          displayError(error)
        });

      setPeriodId('')
    }
  }

  const handlePeriodRedirect = (e, period) => {
    e.preventDefault();
    // TODO get period start and end from somewere

    // setPeriodDate({
    //   "start": period.startDate,
    //   "end": period.startDate,
    // })

    navigate(`/excise-period/${period.excisePeriodId}`);
  }

  const downloadSAPXML = (e, id) => {
    e.preventDefault();
    setState((state) => ({...state, showOverlayLoader: true}));
    const header = authHeader()

    axios.get(`${endpoints.DOWNLOAD_SAP_XML}/${id}`, {headers: header,})
      .then((response) => {
        downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
        setState((state) => ({...state, showOverlayLoader: false}));
      })
      .catch(error => {
        displayError(error)
      });
  }

  const downloadOrgCSV = (e, id) => {
    e.preventDefault();
    setState((state) => ({...state, showOverlayLoader: true}));

    browserDownloadFile(`${endpoints.GENERATE_CSV_FILE}/${id}`);

    setTimeout(() => setState((state) => ({...state, showOverlayLoader: false})), 9000);
  }

  const downloadModCSV = (e, id) => {
    e.preventDefault();
    setState((state) => ({...state, showOverlayLoader: true}));

    browserDownloadFile(`${endpoints.DOWNLOAD_MODIFIED_CSV}/${id}`);

    setTimeout(() => setState((state) => ({...state, showOverlayLoader: false})), 9000);
  }

  const downloadWarehouseLog = (e, id) => {
    e.preventDefault();
    const header = authHeader()
    setState((state) => ({...state, showOverlayLoader: true}));

    axios.post(`${endpoints.GENERATE_WAREHOUSE_LOG}/${id}`, {}, {headers: header,})
      .then((response) => {
        downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
        setState((state) => ({...state, showOverlayLoader: false}));
      })
      .catch(error => {
        displayError(error)
      });
  }

  const downloadDeclarationXML = (e, id) => {
    e.preventDefault();
    setState((state) => ({...state, showOverlayLoader: true}));
    const header = authHeader()

    axios.post(`${endpoints.GENERATE_XML_DECLARATION}/?excisePeriodId=${id}`, {}, {headers: header,})
      .then((response) => {
        downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
        setState((state) => ({...state, showOverlayLoader: false}));
      })
      .catch(error => {
        displayError(error)
      });
  }

  const deletePeriod = (e, id) => {
    e.preventDefault();
    setState((state) => ({...state, showOverlayLoader: true}));
    const header = authHeader()

    axios.delete(`${endpoints.DELETE_PERIOD}/${id}`, {headers: header})
      .then((response) => {
        setShowModal(false)
        setState((state) => ({...state, showOverlayLoader: false}));
        toast.success("Периодът е успешно изтрит!");

        if (startDate && endDate) {
          sendPeriod();
          getPeriodsOptions();
        } else if (selectedPeriodId != null) {
          setPeriods(null)
          setPeriodId('')
          setState((state) => ({...state, isTableVisible: false}));
          getPeriodsOptions();
        }
      })
      .catch(error => {
        displayError(error)
      });
  }

  return <>
    <div className="container pt-4">
      <h4 className="text-center mt-5 mb-4">Моля, задайте начална и крайна дата за периодите, които искате да прегледате.
      </h4>

      <div className="row d-flex mb-4 justify-content-center">
        <Col md={3}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Изберете начална дата"
            dateFormat="dd/MM/yyyy"
          />
        </Col>

        <Col md={3}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="Изберете крайна дата"
            dateFormat="dd/MM/yyyy"
          />
        </Col>

        <Col md={2}>
          <Button onClick={() => sendPeriod()} variant="primary" className="w-100">Задай</Button>
        </Col>
      </div>

      <h4 className="text-center mb-4"> Или използвайте падащото меню за бърз достъп до определен период</h4>

      <div className="row justify-content-center">
        <Col md={4}>
          <Form.Select aria-label="Default select example" onChange={(event) => handlePredefinedPeriodSelected(event)} value={selectedPeriodId}>
            <option value="">Изберете единичен период</option>
            {periodsOptions !== null && periodsOptions.map(period => (
              <option key={period.id} value={period.id}>
                {convertDate(new Date(period.startDate)).toISOString().split('T')[0]}
                -
                {convertDate(new Date(period.endDate)).toISOString().split('T')[0]}
                -
                {period.taxWarehouseTown}
              </option>
            ))}
          </Form.Select>
        </Col>
      </div>

      {state.showOverlayLoader === true && (
        <div
          className="d-flex align-items-center justify-content-center ovarlay-loader"
          style={{minHeight: "300px"}}
        >
          <div className="text-center">
            <img src={loader} alt=""/>
          </div>
        </div>
      )}
    </div>
    {(periods !== null && state.isTableVisible) && (
      <Styles>
        <div className="container-fluid pt-4 ">
          <Table bordered>
            <thead>
            <tr>
              <th>Статус</th>
              <th>Начална дата</th>
              <th>Крайна дата</th>
              <th>Потребител създал период</th>
              <th>Потребител архивирал период</th>
              <th>Стартиран на</th>
              <th>Архивиран на</th>
              <th>Склад</th>
              {!state.isSuperAdmin && <>
                <th>SAP XML</th>
                <th>Оригинално CSV</th>
                <th>Потребителско CSV</th>
                <th>Складова наличност</th>
                <th>Митническа декларация</th>
                <th>Бърза връзка към акцизния период</th>
              </>}
              {state.isSuperAdmin && (
                <th>Изтрий период</th>
              )}
            </tr>
            </thead>

            <tbody>
            {periods.map((period, index) => <>
              <tr key={`period-${index}`}>
                <td>
                  {period.isArchived ? (
                    <Icon.LockFill/>
                  ) : (
                    <Icon.UnlockFill/>
                  )
                  }
                </td>
                <td className="text-nowrap">{convertDate(new Date(period.startDate)).toISOString().split('T')[0]}</td>
                <td className="text-nowrap">{convertDate(new Date(period.endDate)).toISOString().split('T')[0]}</td>

                <td>{period.userStarted}</td>
                <td>{period.userCompleted}</td>

                <td className="text-nowrap">{convertDate(new Date(period.startedOn)).toISOString().split('T')[0]}</td>

                <td className="text-nowrap">
                  {period.completedOn !== null && convertDate(new Date(period.completedOn)).toISOString().split('T')[0]}
                </td>

                <td>{period.warehouse}</td>

                {!state.isSuperAdmin && (<>
                  <td>
                    <a href="" onClick={(e) => downloadSAPXML(e, period.excisePeriodId)}>
                      <Icon.FileEarmarkText/>
                    </a>
                  </td>
                  <td>
                    <a href="" onClick={(e) => downloadOrgCSV(e, period.excisePeriodId)}>
                      <Icon.FileEarmarkText/>
                    </a>
                  </td>
                  <td>
                    <a href="" onClick={(e) => downloadModCSV(e, period.excisePeriodId)}>
                      <Icon.FileEarmarkText/>
                    </a>
                  </td>
                  <td>
                    <a href="" onClick={(e) => downloadWarehouseLog(e, period.excisePeriodId)}>
                      <Icon.FileEarmarkText/>
                    </a>
                  </td>
                  <td>
                    <a href="" onClick={(e) => downloadDeclarationXML(e, period.excisePeriodId)}>
                      <Icon.FileEarmarkText/>
                    </a>
                  </td>
                  <td>
                    <a href="" onClick={(e) => handlePeriodRedirect(e, period)}>
                      <Icon.BoxArrowUpRight/>
                    </a>
                  </td>
                </>)}
                {state.isSuperAdmin && (
                  <td>
                    <a className="align-items-center justify-content-center" href="" onClick={(e) => {
                      e.preventDefault();
                      setShowModal(true)
                    }}>
                      <Icon.Trash className="align-items-center justify-content-center" style={{color: "red"}}/>
                    </a>
                  </td>
                )}
              </tr>

              <Modal centered show={show} onHide={() => setShowModal(false)}>
                <Modal.Header className="fs-5 text-danger text-center" closeButton>
                  Сигурни ли сте, че искате да изтриете този период?
                </Modal.Header>
                <Modal.Footer>
                  <Button className="px-5 mx-5" variant="secondary" onClick={() => setShowModal(false)}>
                    Не
                  </Button>
                  <Button className="px-5 mx-5" variant="danger" onClick={(e) => deletePeriod(e, period.excisePeriodId)}>
                    Изтрий
                  </Button>
                </Modal.Footer>
              </Modal>
            </>)}

            </tbody>
          </Table>
        </div>
      </Styles>
    )}
  </>
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedPeriodsFilter);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addUser,
  removeUser,
  getAllUsers,
  getPaginatedUsers,
  updateUser,
  activateUser,
} from "../store/users/action";
import {
  Button,
  Row,
  Col,
  Table,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";

import { useSelector } from "react-redux";
import { translateRoleTypeEnumValue } from "../helpers/translateHardcodedData";
import { TableStyles } from "../components/Style.js"


const mapStateToProps = (state) => ({
  users: state.users.users,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: bindActionCreators(getAllUsers, dispatch),
    addUser: bindActionCreators(addUser, dispatch),
    removeUser: bindActionCreators(removeUser, dispatch),
    getPaginatedUsers: bindActionCreators(getPaginatedUsers, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch),
    activateUser: bindActionCreators(activateUser, dispatch),
  };
};

const Index = ({
  props,
  users,
  addUser,
  removeUser,
  getAllUsers,
  getPaginatedUsers,
  updateUser,
  activateUser,
}) => {
  useEffect(() => {
    getAllUsers();
  }, []);

  const userTypes = [
    {
      value: 1,
      label: "SuperAdmin",
    },
    {
      value: 2,
      label: "RegularUser",
    },
  ];

  const [newUser, setNewUser] = useState({
    firstName: "",
    middleName: "",
    familyName: "",
    egn: "",
    username: "",
    password: "",
    userTypeId: "",
    isActive: true,
  });

  const [dataUsers, setData] = useState([]);

  useEffect(() => {
    setData(users);
  }, [users]);

  const handleChange = (e) => {
    //console.log(e);
    const { name, value } = e.target;
    setNewUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserAdd = () => {
    addUser(newUser);
    //console.log(users);
    // clear the data in template
    setNewUser((prevState) => ({
      ...prevState,

      firstName: "",
      middleName: "",
      familyName: "",
      egn: "",
      username: "",
      password: "",
      userTypeId: "",
      isActive: true,
    }));
  };

  const updateFieldChanged = (index) => (e) => {
    let newArr = [...dataUsers]; // copying the old datas array
    newArr[index][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to

    setData(newArr);
  };

  const updateRole = (index) => (e) => {
    let newArr = [...dataUsers];
    newArr[index].userTypeId = e.target.value;

    setData(newArr);
  };

  const handleUpdateUser = (obj) => {
    //console.log(obj);
    updateUser(obj);
  };

  const handleUserActivate = (obj) => {
    activateUser(obj);
  };

  return (
    <div>
      <TableStyles>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Father's name</th>
              <th>Family Name</th>
              <th>EGN</th>
              <th>Username</th>
              <th>Password</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {dataUsers.map((user, index) => {
              return (
                <tr key={user.id} className={user.isActive ? "" : "disabled"}>
                  <td>
                    <InputGroup className="mb-0">
                      <FormControl
                        placeholder="firstName"
                        aria-label="firstName"
                        aria-describedby="basic-addon1"
                        value={user.firstName}
                        name="firstName"
                        onChange={updateFieldChanged(index)}
                      />
                    </InputGroup>
                  </td>

                  <td>
                    <InputGroup className="mb-0">
                      <FormControl
                        placeholder="middleName"
                        aria-label="middleName"
                        aria-describedby="basic-addon1"
                        value={user.middleName}
                        name="middleName"
                        onChange={updateFieldChanged(index)}
                      />
                    </InputGroup>
                  </td>

                  <td>
                    <InputGroup className="mb-0">
                      <FormControl
                        placeholder="familyName"
                        aria-label="familyName"
                        aria-describedby="basic-addon1"
                        value={user.familyName}
                        onChange={updateFieldChanged(index)}
                        name="familyName"
                      />
                    </InputGroup>
                  </td>

                  <td>
                    <InputGroup className="mb-0">
                      <FormControl
                        placeholder="egn"
                        aria-label="egn"
                        aria-describedby="basic-addon1"
                        value={user.egn}
                        name="egn"
                        onChange={updateFieldChanged(index)}
                      />
                    </InputGroup>
                  </td>

                  <td>
                    <InputGroup className="mb-0">
                      <FormControl
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={user.username}
                        name="username"
                        onChange={updateFieldChanged(index)}
                      />
                    </InputGroup>
                  </td>

                  <td>
                    <InputGroup className="mb-0">
                      <FormControl
                        placeholder="password"
                        aria-label="password"
                        aria-describedby="basic-addon1"
                        value={user.password}
                        name="password"
                        onChange={updateFieldChanged(index)}
                      />
                    </InputGroup>
                  </td>

                  <td>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={updateRole(index)}
                      value={user.userTypeId}
                    >
                      <option>Select Role</option>

                      {userTypes.map((role) => (
                        <option key={role.value} value={role.value}>
                          {role.label}
                        </option>
                      ))}
                    </Form.Select>
                  </td>

                  <td>
                    <div className="actions d-flex">
                      <Button
                        onClick={() => handleUpdateUser(user)}
                        variant="primary"
                      >
                        Save
                      </Button>

                      <Button
                        onClick={() => handleUserActivate(user)}
                        variant="primary"
                        className="ms-2"
                      >
                        {user.isActive ? "Deactivate" : "Activate"}
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}

            <tr>
              <td>
                <InputGroup className="mb-0">
                  <FormControl
                    placeholder="First name"
                    aria-label="First name"
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={newUser.firstName}
                    name="firstName"
                  />
                </InputGroup>
              </td>

              <td>
                <InputGroup className="mb-0">
                  <FormControl
                    placeholder="middle Name"
                    aria-label="middle Name"
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={newUser.middleName}
                    name="middleName"
                  />
                </InputGroup>
              </td>

              <td>
                <InputGroup className="mb-0">
                  <FormControl
                    placeholder="Family name"
                    aria-label="Family name"
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={newUser.familyName}
                    name="familyName"
                  />
                </InputGroup>
              </td>

              <td>
                <InputGroup className="mb-0">
                  <FormControl
                    placeholder="egn"
                    aria-label="egn"
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={newUser.egn}
                    name="egn"
                  />
                </InputGroup>
              </td>

              <td>
                <InputGroup className="mb-0">
                  <FormControl
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={newUser.username}
                    name="username"
                  />
                </InputGroup>
              </td>

              <td>
                <InputGroup className="mb-0">
                  <FormControl
                    placeholder="password"
                    aria-label="password"
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={newUser.password}
                    name="password"
                  />
                </InputGroup>
              </td>

              <td>
                <Form.Select
                  aria-label="Default select example"
                  name="userTypeId"
                  onChange={handleChange}
                >
                  <option>Select Role</option>

                  {userTypes.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </Form.Select>
              </td>

              <td>
                <div className="actions">
                  <Button onClick={() => handleUserAdd()} variant="primary">
                    Add
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </TableStyles>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import {
  Home,
  Users,
  Login,
  Profile,
  ArchivedPeriods,
  NewPeriodDefine,
  ExcisePeriod,
  TemporaryPeriod,
  PeriodArchivingSuccess,
  UploadMapping,
  HomeLogistic,
} from "./pages";
import { Provider } from "react-redux";
import { store } from "./store/store";
import MainLayout from "./Layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoleRoute from "./RoleRoute";
import SuperAdminHome from "./pages/SuperAdminHome";
import UploadLogisticMappings from "./pages/UploadCustomersMapping";
import UploadLogisticXML from "./pages/UploadLogisticXML";
import UploadInternalMarketXML from "./pages/UploadInternalMarketXML";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>

        <MainLayout>
          <Routes>
            <Route path="/profile" element={<ProtectedRoute />}>
              <Route path="/profile" element={<Profile />} />
            </Route>

            <Route
              path="/archived-periods"
              element={<RoleRoute requiredRole={"RegularUser"} />}
            >
              <Route path="/archived-periods" element={<ArchivedPeriods />} />
            </Route>

            <Route
              path="/new-period-define"
              element={<RoleRoute requiredRole={"RegularUser"} />}
            >
              <Route path="/new-period-define" element={<NewPeriodDefine />} />
            </Route>

            <Route
              path="/excise-period/:id"
              element={<RoleRoute requiredRole={"RegularUser"} />}
            >
              <Route path="/excise-period/:id" element={<ExcisePeriod />} />
            </Route>

            <Route
              path="/temporary-period"
              element={<RoleRoute requiredRole={"RegularUser"} />}
            >
              <Route path="/temporary-period" element={<TemporaryPeriod />} />
            </Route>

            <Route
              path="/period-archiving-success"
              element={<RoleRoute requiredRole={"RegularUser"} />}
            >
              <Route
                path="/period-archiving-success"
                element={<PeriodArchivingSuccess />}
              />
            </Route>

            <Route
              path="/users"
              element={<RoleRoute requiredRole={"SuperAdmin"} />}
            >
              <Route path="/users" element={<Users />} />
            </Route>

            <Route
              path="/super-admin-home"
              element={<RoleRoute requiredRole={"SuperAdmin"} />}
            >
              <Route path="/super-admin-home" element={<SuperAdminHome />} />
            </Route>
            <Route
              path="/logistics-home"
              element={<RoleRoute requiredRole={"Logistic"} />}
            >
              <Route path="/logistics-home" element={<HomeLogistic />} />
            </Route>
            <Route
              path="/upload-mapping"
              element={<RoleRoute requiredRole={"RegularUser"} />}
            >
              <Route path="/upload-mapping" element={<UploadMapping />} />
            </Route>
            <Route
              path="/upload-customers-mapping"
              element={<RoleRoute requiredRole={"Logistic"} />}
            >
              <Route
                path="/upload-customers-mapping"
                element={<UploadLogisticMappings />}
              />
            </Route>
            <Route
              path="/upload-logistic-xml"
              element={<RoleRoute requiredRole={"Logistic"} />}
            >
              <Route
                path="/upload-logistic-xml"
                element={<UploadLogisticXML />}
              />
            </Route>
            <Route
              path="/upload-internal-market-xml"
              element={<RoleRoute requiredRole={"Logistic"} />}
            >
              <Route
                path="/upload-internal-market-xml"
                element={<UploadInternalMarketXML />}
              />
            </Route>

            <Route
              path="/"
              element={<RoleRoute requiredRole={"RegularUser"} />}
            >
              <Route path="/" element={<Home />} />
            </Route>
          </Routes>
        </MainLayout>

        <ToastContainer autoClose={5000} />
        {/* <Footer /> */}
      </Router>
    </Provider>
  );
}

export default App;

import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import users from './users/reducer'
import auth from './auth/reducer'
import message from './message/reducer'
import period from './period/reducer'

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const combinedReducer = combineReducers({
  // add all
  users,
  auth,
  message,
  period
})

const reducer = (state, action) => {
  return combinedReducer(state, action)
}

const initStore = () => {
  return createStore(reducer
    //, bindMiddleware([thunkMiddleware])
    )
}

//export const wrapper = createWrapper(initStore)
export const store = createStore(
  reducer,
  bindMiddleware([thunkMiddleware])
  //, composeWithDevTools(getMiddleware())
);

// store.subscribe(() => {
//     if (store.getState().action.indexOf('counter') !== -1) {
//         console.log('subscribed for messanger actions', store.getState());
//     }
// });

import axios from 'axios'

export const periodActionTypes = {
  SET_PERIOD_DATE: 'SET_PERIOD_DATE',
  CLOSE_PERIOD: 'CLOSE_PERIOD',
}

export const setPeriodDate = (date) => (dispatch) => {
  return dispatch({ type: periodActionTypes.SET_PERIOD_DATE, payload: date })
}

// export const setPeriodEnd = (date) => (dispatch) => {
//   console.log(date)
//   return dispatch({ type: periodActionTypes.SET_END_DATE, payload: date })
// }
export const closePeriod = (data) => (dispatch) => {
  return dispatch({ type: periodActionTypes.CLOSE_PERIOD, payload: data })
}





import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n";
import i18next from "i18next";
//require("dotenv").config();

const lang = "bg";
i18next.changeLanguage(lang);

ReactDOM.render(
	<React.Fragment>
    	<App />
  	</React.Fragment>,

  	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { NavLink } from "react-router-dom";
import React from "react";

const HomeLogistic = () => {
  return (
    <div className="home">
      <div className="container py-5">
        <div className="row align-items-center my-5">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul className="nav list-group flex-column">
              <h4 className="text-center fw-bold">Експорт</h4>
              <li
                className=" list-group-item list-group-item-action  nav-item"
                style={{ fontSize: "20px", borderRadius: "10px" }}
              >
                <NavLink
                  className="nav-link"
                  to="/upload-logistic-xml"
                  style={{ color: "black", textAlign: "center" }}
                >
                  Качване на XML
                </NavLink>
              </li>
              <li style={{ height: "5px" }}></li>
              <li
                className="list-group-item list-group-item-action  nav-item"
                style={{ fontSize: "20px", borderRadius: "10px" }}
              >
                <NavLink
                  className="nav-link"
                  to="/upload-customers-mapping"
                  style={{ color: "black", textAlign: "center" }}
                >
                  Качване на Мапинг
                </NavLink>
              </li>
              <h4 className="text-center mt-2 fw-bold">Вътрешен пазар</h4>
              <li style={{ height: "5px" }}></li>
              <li
                className="list-group-item list-group-item-action  nav-item"
                style={{ fontSize: "20px", borderRadius: "10px" }}
              >
                <NavLink
                  className="nav-link"
                  to="/upload-internal-market-xml"
                  style={{ color: "black", textAlign: "center" }}
                >
                  Качване на XML за Вътрешен Пазар
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLogistic;

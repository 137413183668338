import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { Col, Row, Button, Form } from "react-bootstrap";
import Chart from "../components/Chart.js";
import Table from "react-bootstrap/Table";
import * as Icon from "react-bootstrap-icons";
import * as endpoints from "../constants/endpoints";
import loader from "../images/loader2.gif";
import { toast } from "react-toastify";
import styled from "styled-components";
import { TableStyles } from "../components/Style.js";
import { downloadFile } from "../helpers/downloadFile.js";
import axios from "axios";
import authHeader from "../services/auth-header";
import { setPeriodDate } from '../store/period/action'
import {convertDate} from "../helpers/dateFormatter";
import { displayError } from "../helpers/formatErrorCode"
import {currencyFormat, spacingFormat} from "../helpers/currencyFormatter";
import {ArrowLeft} from "react-bootstrap-icons";
import PeriodStatisticsTables from "../components/PeriodStatisticsTables";
import {browserDownloadFile} from "../helpers/browserDownloadFile";

const mapStateToProps = (state) => ({
  period: state.period.period,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPeriodDate: bindActionCreators(setPeriodDate, dispatch),
  }
}

const Styles = styled(TableStyles)`
  padding: 0;

  table {
    th,
    td {
      padding: 10px;
    }
  }
`;

const Index = ({ user, period, setPeriodDate }) => {
  const { id } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [isSAPUploaded, setIsSAPUploaded] = useState(false);

  const [isCSVLoaded, setIsCSVLoaded] = useState(false);
  const [isCSVUploaded, setIsCSVUploaded] = useState(false);
  const [periodData, setPeriod] = useState(null);

  const [state, setState] = useState({
    file_sap: null,
    file_csv: null,
    isLoading: false,
    showOverlayLoader: false,
    isSAPLoaded: false,
    isCSVGenerated: false,
    isCSVUploaded: false,
    csvStatisticsOrg: null,
    csvStatisticsMod: null,
    exciseDeclarationGroupStatistics: null,
    stockLogStatistic: null,
    warehouse: null
  });

  const fileSAPRef = useRef();
  const fileCSVRef = useRef();

  const handleChange = (e) => {
    const file = e.target.files[0];

    setState((state) => ({ ...state, file_sap: file }));
  };

  useEffect(() => {
    if (state.file_sap !== null) {
      uploadSAPFile();
    }
  }, [state.file_sap]);

  useEffect(() => {
    if (state.file_csv !== null) {
      uploadCSVFile();
    }
  }, [state.file_csv]);

  const onFileChange = (event, fileName) => {
    const file = event.target.files[0];

    if (fileName === "file_sap") {
      setState((state) => ({ ...state, file_sap: file }));
    } else {
      setState((state) => ({ ...state, file_csv: file }));
    }
  };

  const uploadSAPFile = () => {
    setState((state) => ({ ...state, showOverlayLoader: true }));
    const file = state.file_sap;
    const data = new FormData();
    data.append("sapXmlDocument", file);

    const header = authHeader();
    header["Content-Type"] = "multipart/form-data";

    axios
      .post(`${endpoints.IMPORT_TEMPORARY_SAP_FILE}`, data, {
        headers: header,
      })
      .then((response) => {
        toast.success("SAP file was uploaded");
        if (response.data.errors && response.data.errors.length > 0) {
          for (const error of response.data.errors) {
            toast.error(error, {autoClose: false});
          }
        }

        if ( response.data.data.excisePeriodOriginalCsvStatisticsResponse !== null ) {
          setState((state) => ({
            ...state,
            csvStatisticsOrg: response.data.data.excisePeriodOriginalCsvStatisticsResponse,
            isSAPLoaded: true,
          }));
        }

        if ( response.data.data.excisePeriodModifiedCsvStatisticsResponse !== null ) {
          setState((state) => ({
            ...state,
            csvStatisticsMod: response.data.data.excisePeriodModifiedCsvStatisticsResponse,
          }));
        }

        if ( response.data.data.exciseDeclarationGroupStatistics !== null ) {
          setState((state) => ({
            ...state,
            exciseDeclarationGroupStatistics: response.data.data.exciseDeclarationGroupStatistics,
          }));
        }

        if ( response.data.data.excisePeriodStockLogStatisticResponse !== null ) {
          setState((state) => ({
            ...state,
            stockLogStatistic: response.data.data.excisePeriodStockLogStatisticResponse,
          }));
        }

        setState((state) => ({ ...state, showOverlayLoader: false }));
      })
      .catch(error => {
        displayError(error)
        setState((state) => ({ ...state, showOverlayLoader: false }));
      });
  };

  const generateCSVFile = () => {
    setState((state) => ({...state, showOverlayLoader: true}));

    browserDownloadFile(`${endpoints.GENERATE_TEMPORARY_CSV}`);

    setTimeout(() => setState((state) => ({...state, showOverlayLoader: false, isCSVGenerated: true})), 9000);
  }

  const uploadCSVFile = () => {
    setState((state) => ({ ...state, showOverlayLoader: true }));

    const file = state.file_csv;
    const data = new FormData();
    data.append("csvDocument", file);

    const header = authHeader();
    header["Content-Type"] = "multipart/form-data";

    axios
      .post(`${endpoints.UPLOAD_TEMPORARY_MODIFIED_CSV}`, data, {
        headers: header,
      })
      .then((response) => {
        toast.success("CSV file was uploaded");

        if (response.data.errors && response.data.errors.length > 0) {
          for (const error of response.data.errors) {
            toast.error(error, {autoClose: false});
          }
        }

        if ( response.data.data.excisePeriodOriginalCsvStatisticsResponse !== null ) {
          setState((state) => ({
            ...state,
            csvStatisticsOrg: response.data.data.excisePeriodOriginalCsvStatisticsResponse,
            isSAPLoaded: true,
          }));
        }

        if ( response.data.data.excisePeriodModifiedCsvStatisticsResponse !== null ) {
          setState((state) => ({
            ...state,
            csvStatisticsMod: response.data.data.excisePeriodModifiedCsvStatisticsResponse,
          }));
        }

        if ( response.data.data.exciseDeclarationGroupStatistics !== null ) {
          setState((state) => ({
            ...state,
            exciseDeclarationGroupStatistics: response.data.data.exciseDeclarationGroupStatistics,
          }));
        }

        if ( response.data.data.excisePeriodStockLogStatisticResponse !== null ) {
          setState((state) => ({
            ...state,
            stockLogStatistic: response.data.data.excisePeriodStockLogStatisticResponse,
          }));
        }

        setState((state) => ({ ...state, showOverlayLoader: false }));
      })
      .catch(error => {
        displayError(error)
        setState((state) => ({ ...state, showOverlayLoader: false }));
      });
  };

  const generateWarehouseExport = () => {
    setState((state) => ({ ...state, showOverlayLoader: true }));
    const header = authHeader();

    axios
      .post(
        `${endpoints.GENERATE_TEMP_WAREHOUSE_EXPORT}`,
        {},
        { headers: header }
      )
      .then((response) => {
        toast.success("Warehouse log was generated");

        setState((state) => ({ ...state, showOverlayLoader: false }));

        downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
      })
      .catch(error => {
        displayError(error)
        setState((state) => ({ ...state, showOverlayLoader: false }));
      });
  };

  const generateXML = () => {
    setState((state) => ({ ...state, showOverlayLoader: true }));
    const header = authHeader();

    axios
      .post(
        `${endpoints.GENERATE_TEMP_XML_DECLARATION}`,
        {},
        { headers: header }
      )
      .then((response) => {
        toast.success("XML file was generated");

        setState((state) => ({ ...state, showOverlayLoader: false }));

        downloadFile(response.data, response.headers['content-disposition'].match(/filename=(.+);/)[1]);
      })
      .catch(error => {
        displayError(error)
        setState((state) => ({ ...state, showOverlayLoader: false }));
      });
  };

  return <>
    <div className="container-fluid pt-4">

      <div className="d-flex col-12 mb-2 justify-content-center align-content-center">
        <div className="d-flex col-3 mb-4 justify-content-start">
          <ArrowLeft width="32" height="32" onClick={() => navigate(-1)} className="me-3"/>
          <h4 className="text-center">{state.warehouse}</h4>
        </div>
          <Button variant="success" className="mb-4" onClick={() => fileSAPRef.current.click()}>Зареди SAP файл</Button>

          <input ref={fileSAPRef} onChange={(e) => onFileChange(e, "file_sap")} multiple={false} type="file" hidden/>

          <Button disabled={!state.isSAPLoaded} variant="primary" className="mx-2 mb-4" onClick={generateCSVFile}>Генерирай справка в CSV</Button>

          <Button disabled={!state.isCSVGenerated} variant="primary" className="mb-4"
                onClick={() => fileCSVRef.current.click()}>Зареди справка в CSV</Button>

          <input ref={fileCSVRef} onChange={(e) => onFileChange(e, "file_csv")} multiple={false} type="file" hidden/>

          <Button disabled={!state.isSAPLoaded} variant="outline-dark" className="mx-2 mb-4" onClick={generateWarehouseExport}>
          Генерирай складова наличност</Button>

          <Button disabled={!state.isSAPLoaded} variant="dark mb-4" onClick={generateXML}>Генерирай акцизна декларация</Button>
      </div>

      {state && <PeriodStatisticsTables period={state}/>}

      {state.showOverlayLoader === true && (
        <div
          className="d-flex align-items-center justify-content-center ovarlay-loader"
          style={{ minHeight: "300px" }}
        >
          <div className="text-center">
            <img src={loader} alt="" />
          </div>
        </div>
      )}
    </div>
  </>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

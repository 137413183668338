const REACT_APP_API = process.env.REACT_APP_API;

// Login
export const LOGIN = REACT_APP_API + "Login/Login";
export const REGISTER = "";
export const CREATE_USER = REACT_APP_API + "Users/CreateUser";
export const USER_GET_ALL = REACT_APP_API + "Users/GetAllUsers";
export const USER_UPDATE = REACT_APP_API + "Users/UpdateUser";
export const USER_GET_PROFILE = REACT_APP_API + "Users/GetUserProfile";

export const GET_WAREHOUSES = REACT_APP_API + "ExcisePeriod/GetWarehouses";
export const GET_WAREHOUSE = REACT_APP_API + "ExcisePeriod/GetWarehouse";

export const GET_PERIODS_ALL = REACT_APP_API + "ExcisePeriod/GetAllPeriods";
export const GET_PERIOD_STATISTICS =
  REACT_APP_API + "ExcisePeriod/GetExciseStatistics";

export const GET_PERIOD_BY_ID =
  REACT_APP_API + "ExcisePeriod/GetExcisePeriodById";
export const GET_PERIODS_BY_DATE =
  REACT_APP_API + "ExcisePeriod/GetArchivedPeriodsByDate";

export const CREATE_PERIOD = REACT_APP_API + "ExcisePeriod/CreateExcisePeriod";
export const IMPORT_SAP = REACT_APP_API + "ExcisePeriod/ImportSapData";
export const IMPORT_CSV = REACT_APP_API + "ExcisePeriod/ImportModifiedCSV";
export const GENERATE_CSV_FILE = REACT_APP_API + "ExcisePeriod/GenerateCsvFile";
export const GENERATE_XML_DECLARATION =
  REACT_APP_API + "ExcisePeriod/GenerateXmlDeclaration";
export const GENERATE_WAREHOUSE_LOG =
  REACT_APP_API + "ExcisePeriod/GenerateWarehouseStockLog";

export const ARCHIVE_PERIOD =
  REACT_APP_API + "ExcisePeriod/ArchiveExcisePeriod";
export const ACTIVATE_PERIOD =
  REACT_APP_API + "ExcisePeriod/UnarchiveExcisePeriod";
export const DELETE_PERIOD = REACT_APP_API + "ExcisePeriod/DeleteExcisePeriod";

export const DOWNLOAD_SAP_XML =
  REACT_APP_API + "ExcisePeriod/DownloadSapXmlFile";
export const DOWNLOAD_MODIFIED_CSV =
  REACT_APP_API + "ExcisePeriod/DownloadModifiedCsvFile";

export const IMPORT_TEMPORARY_SAP_FILE =
  REACT_APP_API + "ExcisePeriod/ImportTemporarySapData";
export const GENERATE_TEMPORARY_CSV =
  REACT_APP_API + "ExcisePeriod/GenerateTemporaryCsvFile";
export const UPLOAD_TEMPORARY_MODIFIED_CSV =
  REACT_APP_API + "ExcisePeriod/ImportTemporaryModifiedCSV";
export const GENERATE_TEMP_WAREHOUSE_EXPORT =
  REACT_APP_API + "ExcisePeriod/GenerateTemporaryWarehouseStockLog";
export const GENERATE_TEMP_XML_DECLARATION =
  REACT_APP_API + "ExcisePeriod/GenerateTemporaryXmlDeclaration";
export const UPLOAD_NUMBER_OF_PACKAGES_MAPPING =
  REACT_APP_API + "Mapper/ImportNumberOfPackagesMap";
export const UPLOAD_REPACK_MAPPING = REACT_APP_API + "Mapper/ImportRepackMap";
export const UPLOAD_LOGISTIC_MAPPING =
  REACT_APP_API + "Mapper/ImportConsigneeTraders";
export const IMPORT_ALCOHOL_PERCENTAGE =
  REACT_APP_API + "Mapper/ImportAlcoholPercentage";
export const GET_CUSTOMERS = REACT_APP_API + "Logistic/GetConsigneeTraders";
export const GET_INTERNAL_MARKET_ERRORS =
  REACT_APP_API + "Logistic/GetInternalMarketErrors";
export const IMPORT_INTERNAL_MARKET_XML =
  REACT_APP_API + "Logistic/ImportInternalMarketXml";
export const IMPORT_LOGISTIC_XML =
  REACT_APP_API + "Logistic/ImportExternalMarketXml";

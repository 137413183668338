import React, {useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {login} from "../store/auth/action";
import styled from 'styled-components'
import jwt_decode from "jwt-decode";
import bgImage from "../images/bg.png";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const PageContainer = styled.div`
  min-height: 100vh;
  //background-color: #8796e5;
  //background-image: url(../images/bg.png);
  //background-image: url(require('../images/bg.png'));
  display: flex;
`

const LeftPart = styled.div`
  min-height: 100vh;
  background-color: #fff;
  width: 33%;
  padding: 0 20px;

  .card {
    border: none;
  }
`

const RightPart = styled.div`
  min-height: 100vh;
  width: 66.66%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
`

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const {isLoggedIn, user} = useSelector(state => state.auth);
  const {message} = useSelector(state => state.message);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          props.history.push("/profile");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (user !== null) {
    var decoded = jwt_decode(user.token);
    const roleStr = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    const nameStr = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'

    const role = decoded[roleStr];
    const name = decoded[nameStr];


    if (isLoggedIn && role === 'SuperAdmin') {
      return <Navigate to="/super-admin-home"/>;
    } else if (isLoggedIn && role === 'Logistic') {
      return <Navigate to="/logistics-home"/>;
    } else {
      return <Navigate to="/"/>;
    }
  }

  return (
    <PageContainer>
      <LeftPart>
        <div className="card card-container">
          <img src="../kamenitza_big_logo.jpeg" alt=""/>

          <Form onSubmit={handleLogin} ref={form}>
            <div className="form-group mb-4">
              <label htmlFor="username">Username</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-group mb-4">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}

            <CheckButton style={{display: "none"}} ref={checkBtn}/>
          </Form>
        </div>
      </LeftPart>

      <RightPart style={{backgroundImage: `url(${bgImage})`}}>&nbsp;</RightPart>
    </PageContainer>
  );
};

export default Login;

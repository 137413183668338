export const downloadFile = (data, name) => {
	var type = name.split('.').pop();
	var blob = new Blob([data], {type: `text/${type};charset=utf-8`});
	var url = URL.createObjectURL(blob);
	var a = document.createElement('a');
	a.href = url;
	a.download = name;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

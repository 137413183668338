import axios from 'axios'
import authHeader from "../../services/auth-header";
import * as endpoints from "../../constants/endpoints";

export const usersActionTypes = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  GET_ALL: 'GET_ALL',
  UPDATE: 'UPDATE',
  ACTIVATE: 'ACTIVATE',
  GET_PROFILE: 'GET_PROFILE',
}

export const addUser = (data) => async dispatch => {
  const header = authHeader()
  //header["Content-Type"] = "multipart/form-data";

  try{
    const res = await axios.post(endpoints.CREATE_USER, data, {headers: header })
    .then((response) => {
    });
    dispatch({ type: usersActionTypes.ADD, payload: data })

    getAllUsers()
  }
  catch(e){
      dispatch( {
          type: usersActionTypes.USERS_ERROR,
          payload: console.log(e),
    })
  }
  //const res = await axios.post(`/api/Users/CreateUser`)
  //return dispatch({ type: usersActionTypes.ADD, payload: data })
}
export const removeUser = (id) => (dispatch) => {
  return dispatch({ type: usersActionTypes.REMOVE, payload: id })
}

export const getAllUsers = () => async dispatch => {
  const header = authHeader()
  try{
        //const res = await axios.get(`http://jsonplaceholder.typicode.com/users`)
        const res = await axios.get(endpoints.USER_GET_ALL, {headers: header })
        .then((response) => {
          dispatch( {
              type: usersActionTypes.GET_ALL,
              payload: response.data
          })
        });
    }
    catch(e){
        dispatch( {
            type: usersActionTypes.USERS_ERROR,
            payload: console.log(e),
        })
    }
  //return dispatch({ type: usersActionTypes.GET_ALL })
}

export const updateUser = (data) => async dispatch => {
  const header = authHeader()
  try{
    const res = await axios.post(`${endpoints.USER_UPDATE}/${data.id}`, data, {headers: header })
    .then((response) => {
      dispatch( {
          type: usersActionTypes.UPDATE,
          payload: response.data
      })
    });
  }
  catch(e){
    dispatch( {
        type: usersActionTypes.USERS_ERROR,
        payload: console.log(e),
    })
  }
}

export const activateUser = (data) => async dispatch => {
  const header = authHeader()
  try{
    const res = await axios.post(`${endpoints.USER_UPDATE}/${data.id}`, {isActive: !data.isActive}, {headers: header })
    .then((response) => {
      dispatch( {
          type: usersActionTypes.ACTIVATE,
          payload: response.data
      })
    });
  }
  catch(e){
    dispatch( {
        type: usersActionTypes.USERS_ERROR,
        payload: console.log(e),
    })
  }
}

export const getPaginatedUsers = (data) => async dispatch => {
  const start = data.start;
  const limit = data.limit;
  try{
        const res = await axios.get(`http://jsonplaceholder.typicode.com/users?_start=${start}&_limit=${limit}`)
        dispatch( {
            type: usersActionTypes.GET_ALL,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: usersActionTypes.USERS_ERROR,
            payload: console.log(e),
        })
    }
  //return dispatch({ type: usersActionTypes.GET_ALL })
}

export const getUserProfile = (data) => async dispatch => {
  const header = authHeader()
  try{
        const res = await axios.get(`${endpoints.USER_GET_PROFILE}?userId=${data}`, {headers: header })
        .then((response) => {
          dispatch( {
              type: usersActionTypes.GET_PROFILE,
              payload: response.data
          })
        });
    }
    catch(e){
        dispatch( {
            type: usersActionTypes.USERS_ERROR,
            payload: console.log(e),
        })
    }
}

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { logout } from '../store/auth/action'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import logo from "../images/logo.png";
import jwt_decode from "jwt-decode";

const mapDispatchToProps = (dispatch) => {
  return {
    logout: bindActionCreators(logout, dispatch),
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
})

const HandleLogout = (e, logout)  => {
  e.preventDefault();
  logout()
}

const  Navigation = ({user, logout, props}) => {
  let userName = ''

  if (user !== null) {
    var decoded = jwt_decode(user.user.token);
    const nameStr = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
    userName = decoded[nameStr]


  }

  return (
    <div className="header" style={{padding: "20px 10px"}}>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <Link to={"/"} >
            <img src={logo} alt="" width="80" />
          </Link>

          <a href="" className="d-flex align-items-center logout-link" onClick={(e) => HandleLogout(e, logout)}>
            <span className="mx-2">{userName}</span>

            <Icon.BoxArrowRight size={26}/>

            <span>Log out</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

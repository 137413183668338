import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import {Col, Row, Button, Form, Modal} from 'react-bootstrap'
import { setPeriodDate } from '../store/period/action'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import authHeader from "../services/auth-header";
import * as endpoints from "../constants/endpoints";
import { translateErrorEnumValue } from "../helpers/translateErrorsString.js"
import { convertDate } from "../helpers/dateFormatter";
import { displayError } from "../helpers/formatErrorCode"

// TODO
const mapStateToProps = (state) => ({
  user: state.auth.user,
  period: state.period.period
})

const mapDispatchToProps = (dispatch) => {
  return {
    setPeriodDate: bindActionCreators(setPeriodDate, dispatch),
  }
}

const Index = ({user, period, setPeriodDate}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [show, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getWarehouse()
  }, []);

  const [state, setState] = useState({
    warehouses: null,
    selectedWarehouseId: null
  });

  const onWareHouseSelect = (e, id) => {
    setState((state) => ({ ...state, selectedWarehouseId: id }));
  }

  const getWarehouse = () => {
    const header = authHeader()

    axios.get(`${endpoints.GET_WAREHOUSES}`,{ headers: header, })
     .then((response) => {
       setState((state) => ({ ...state, warehouses: response.data }));
    })
    .catch(error => {
      displayError(error)
    });
  }

  const handleSubmit = (event)  => {
    event.preventDefault();

    const header = authHeader();

    axios.post(endpoints.CREATE_PERIOD,
      {
        "startDate": convertDate(startDate).toISOString(),
        "endDate": convertDate(endDate).toISOString(),
        "warehouseId": state.selectedWarehouseId
      },
      { headers: header, })
      .then((response) => {
        setPeriodDate({
          "start": startDate,
          "end": endDate,
        });

        setShowModal(false)
        navigate(`/excise-period/${response.data.id}`);
      })
      .catch(error => {
        setShowModal(false)
        displayError(error)
      });
  }

  return (
    <div className="container pt-4">
      <h4 className="text-center mt-5 mb-4">Моля изберете склад, начална дата и крайна дата на периода, който подлежи на деклариране</h4>

      <div className="d-flex mb-4 justify-content-center">
        {state.warehouses !== null && state.warehouses.map(warehouse => (
          <Form.Check
            inline
            label={warehouse.taxWarehouseTown}
            name="group1"
            type="radio"
            id={warehouse.id}
            onChange={(e) => onWareHouseSelect(e, warehouse.id)}
            className="form-check-as-button"
            key={warehouse.id}
          />
          ))}
      </div>

      <div className="d-flex mb-4 justify-content-center">
        <Col md={2} className="mx-1">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Избери начална дата"
            dateFormat="dd/MM/yyyy"
          />
        </Col>

        <Col md={2} className="mx-1">
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="Избери крайна дата"
            dateFormat="dd/MM/yyyy"
          />
        </Col>
      </div>

      <div className="text-center">
        <Button
          onClick={(event) => { event.preventDefault(); setShowModal(true)}}
          variant="primary"
          disabled={startDate === null || endDate === null || state.selectedWarehouseId === null}
          >Стартирай</Button>
      </div>

      <Modal centered show={show} onHide={() => setShowModal(false)}>
        <Modal.Header className="fs-5 text-center" closeButton>
          Сигурни ли сте, че искате да създадете период от {startDate ? convertDate(startDate).toISOString().split('T')[0] : null} до {endDate ? convertDate(endDate).toISOString().split('T')[0] : null}?
        </Modal.Header>
        <Modal.Footer className="align-items-center justify-content-center">
          <Button className="px-5 mx-5" variant="secondary" onClick={() => setShowModal(false)}>
            Не
          </Button>
          <Button className="px-5 mx-5" variant="primary" onClick={(e) => handleSubmit(e)}>
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

import React from "react";
import { connect } from 'react-redux';
import {convertDate} from "../helpers/dateFormatter";

const mapStateToProps = (state) => ({
  period: state.period.period,
})

function Index({period}) {
  return (
    <div className="success-page pt-4">
      <div className="container mt-5">
        <h2 className="text-center">Акцизен период</h2>

        <div className="d-flex justify-content-between ">
          <h6>Начална дата: {convertDate(new Date(period.startDate)).toISOString().split('T')[0]}</h6>
          <h6>Крайна дата: {convertDate(new Date(period.endDate)).toISOString().split('T')[0]}</h6>
        </div>

        <h2 className="text-center">е <span style={{color: 'green'}}>успешно</span> архивиран</h2>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(Index);
